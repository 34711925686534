import React from "react"

import { withWishlist } from "./withWishlist"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"
import { WishlistItems } from "./Items/WishlistItems"
import { AccountLoginForm } from "../Account/Login/Form/AccountLoginForm"
import { AccountRegisterForm } from "../Account/Login/Form/AccountRegisterForm"
import { StyledContainer, Border } from "../Wishlist/WishlistStyles"
import { Column, Row, StyledContainer as LoginStyledContainer } from "../Account/Login/AccountLoginStyles"

export const Wishlist = withWishlist(({ hero, meta, ...props }) => (
  <>
    <HeroBanner section={hero} />
    <StyledContainer>
      <WishlistItems {...props} />
    </StyledContainer>
    <Border />
    <LoginStyledContainer>
      <Row>
        <Column border>
          <AccountLoginForm />
        </Column>

        <Column>
          <AccountRegisterForm />
        </Column>
      </Row>
    </LoginStyledContainer>
    <Border />
  </>
))
